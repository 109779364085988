import React from "react";
import { useParams, navigate, Link } from "@reach/router"; // Import from @reach/router
import { Helmet } from "react-helmet-async";

const LoungeAccessFinder = () => {
  return (
    <div className="reward_container container  p-md-5">
      {/* <Helmet>
            <title>{cardInfo.meta_title}</title>
            <meta name="description" content={cardInfo.meta_description} />
            <link rel="canonical" href={cardInfo.meta_url} />
    
            <meta property="og:title" content={cardInfo.meta_title} />
            <meta property="og:type" content="Website" />
            <meta property="og:url" content={cardInfo.meta_url} />
            <meta property="og:description" content={cardInfo.meta_description} />
        
            <meta property="og:site_name" content={"truecards"} />
    
          </Helmet> */}
      <h1>
        Credit Card <strong> Airport Lounge Access Finder </strong>
      </h1>
      <div className="reward_wrap card-wrap">
        <div className="reward_wrap_backbtn">
          <i
            role="button"
            className="fa fa-arrow-circle-o-left"
            onClick={() => navigate("/reward-calculator/select-card")}
          />
          {/* <i role="button" className="fa fa-arrow-circle-o-right" /> */}
        </div>

        <h2>Choose method</h2>
        <div className="row">
          <div  className="col-md-6 col-sm-4 col-6 mb-3">
            <Link
              className="icon-box style-2 rounded"
                to={"/lounge-access/select-card"}
            >
              <i className={"fa fa-credit-card-alt"}></i>
              <span>Browse by Credit Card Name</span>
            </Link>
          </div>

          <div  className="col-md-6 col-sm-4 col-6 mb-3">
            <Link
              className="icon-box style-2 rounded"
              to={"/lounge-access/select-card"}
              >
              <i className={"fa fa-plane"}></i>
              <span>Browse by Airport Name</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoungeAccessFinder;
