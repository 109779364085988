import React from "react";
import Select from "react-select";
import SearchBar from "./SearchBar";
const customStyles = {
  option: (base, state) => ({
    ...base,
    background: "#fff",
    color: "#000",
    borderRadius: state.isFocused ? "0" : 0,
    // border: "1px solid black",
    // borderColor: "#000",
    "&:hover": {
      background: "#eee",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  control: (base, state) => ({
    ...base,
    padding: 2,
    borderColor: "#000",
    cursor: "pointer",
    "&:hover": {
      borderColor: "#000",
    },
  }),
};

const options1 = [
  { label: "Credit Score (Low to High)", value: "credit_score_asc" },
  { label: "Credit Score (High to Low)", value: "credit_score_desc" },
  { label: "Annual Fee (Low to High)", value: "annual_fee_asc" },
  { label: "Annual Fee (High to Low)", value: "annual_fee_desc" },
  { label: "APR (Low to High)", value: "apr_asc" },
  { label: "APR (High to Low)", value: "apr_desc" },
];

export default function CreditCardTopFilters({
  selectedFilters,
  handleSortChange,
  handleRemoveFilter,
}) {
  return (
    <div>
      <div className="items_filter">
        <SearchBar />
        <div className="dropdownSelect one">
          <Select
            styles={customStyles}
            defaultValue={options1[0]}
            options={options1}
            onChange={handleSortChange}
          />
        </div>
      </div>

      {/* Display Applied Filters */}
      {Object.values(selectedFilters).some((filters) => filters.length > 0) && (
        <div className="applied-filters">
          <h6>Applied Filters:</h6>
          <div className="filters-container">
            {["categories", "issuers", "networks", "searchQuery"].map(
              (type) => {
                return selectedFilters[type].length > 0 ? (
                  <div key={type} className="filter-group">
                    {selectedFilters[type]
                      .filter((ele) => ele)
                      .map((filter) => (
                        <div key={filter} className="filter-tag">
                          <span>{filter}</span>
                          <i
                            className="fa fa-times"
                            onClick={() => handleRemoveFilter(type, filter)}
                          ></i>
                        </div>
                      ))}
                  </div>
                ) : null;
              }
            )}
          </div>
        </div>
      )}
    </div>
  );
}
