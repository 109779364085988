import React from "react";
import {
  Popover,
  PopoverTitle,
  PopoverContent,
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const InfoPop = (props) => {
  const { id, title, content } = props;
  return (
    <OverlayTrigger
      trigger={["hover", "focus", "click"]}
      placement="bottom"
      overlay={
        <Popover id={`popover-positioned-${id}`}>
          <Popover.Header as="h3">{title}</Popover.Header>
          <Popover.Body>{content}</Popover.Body>
        </Popover>
      }
    >
      <i className="fa fa-info-circle" role="button" />
    </OverlayTrigger>
  );
};

export default InfoPop;
