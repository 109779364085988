import {
  getBankViewUrl,
  getCardViewUrl,
  getCateViewUrl,
  getCompareCardUrl,
  getRewardCalcUrl,
} from "./helper";

export const handleViewCard = (navigate, card_name) => {
  navigate(getCardViewUrl(card_name));
};

export const handleViewBank = (navigate, bank) => {
  navigate(getBankViewUrl(bank));
};

export const handleViewCategory = (navigate, cate_name) => {
  navigate(getCateViewUrl(cate_name));
};

export const handleViewRewardCalc = (navigate, card_name) => {
  navigate(getRewardCalcUrl(card_name));
};

export const handleCompareCard = (navigate, card_name) => {
  navigate(getCompareCardUrl(card_name));
};