import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import { navigate } from "@reach/router"; // Import from @reach/router

import StarRating from "./StarRating";
import {
  handleViewCard,
  handleViewRewardCalc,
} from "../../utility/navigationHelper";

export default class CCListView extends Component {
  constructor(props) {
    super(props);
    this.cardData = props.cardData;
    this.state = {
      card_list: this.cardData.slice(0, 8),
      height: 0,
    };
    this.onImgLoad = this.onImgLoad.bind(this);
  }
  loadMore = () => {
    let prev_card_list = this.state.card_list;
    let start = prev_card_list.length;
    let end = prev_card_list.length + 4;
    this.setState({
      card_list: [...prev_card_list, ...this.cardData.slice(start, end)],
    });
  };

  onImgLoad({ target: img }) {
    let currentHeight = this.state.height;
    if (currentHeight < img.offsetHeight) {
      this.setState({
        height: img.offsetHeight,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Check if the cardData prop has changed
    if (prevProps.cardData !== this.props.cardData) {
      // If cardData changed, update the state with the new filtered data (first 8 items)
      this.setState({
        card_list: this.props.cardData.slice(0, 8),
      });
    }
  }

  render() {
    console.log(
      this.state.card_list.length,
      this.props.cardData.length,
      this.state.card_list.length != this.props.cardData.length
    );

    return (
      <div className="row">
        {this.state.card_list.map((item, index) => (
          <CardComponent {...item} key={index} />
        ))}
        {this.state.card_list.length != this.props.cardData.length && (
          <div className="col-lg-12">
            <div className="spacer-single"></div>
            <span
              onClick={() => this.loadMore()}
              className="btn-main lead m-auto"
            >
              Load More
            </span>
          </div>
        )}
      </div>
    );
  }
}

const CardComponent = (props) => {
  const {
    index,
    card_name,
    product_image,
    joining_fee,
    annual_fee,
    welcome_benefits,
    rating,
    min_credit_score,
    features,
    welcome_offer,
    lounge_access,
    image_alt,
  } = props;

  const handleBtnClick = () => {
    handleViewCard(navigate, card_name);
  };
  return (
    <div className="d-item">
      <div className="nft__item">
        <div className="nft__item_wrap row ">
          <div className="col-md-12 col-lg-5">
            <div className="outer ">
              <span className="">
                <img
                  //  "./img/banklogo/hdfcbank.jpg"
                  src={`./img/${product_image}`}
                  className="lazy nft__item_preview credit-card animate"
                  // onLoad={this.onImgLoad}
                  role="button"
                  onClick={handleBtnClick}
                  alt={image_alt}
                />
              </span>
            </div>
          </div>
          <div
            role="text"
            className="col-md-12 col-lg-7 nft__item_title text-left"
          >
            <h3 role="button" onClick={handleBtnClick}>
              {card_name} <i className="fa-share-square-o fa"></i>
            </h3>
            <StarRating rating={rating} />
            <div className="nft__item_info" role="text">
              {welcome_benefits?.replace(/'/g, "").split(",").join(", ")}
            </div>

            <div className="nft__item_feeinfo">
              <div>
                <span>Joining Fee:</span>{" "}
                {joining_fee == 0 ? "Free" : `₹${joining_fee} + GST`}
              </div>
              <div>
                <span>Annual Fee:</span>{" "}
                {annual_fee == 0 ? "Free" : `₹${annual_fee} + GST`}
              </div>
              <div>
                <span>Min Credit Score:</span> {min_credit_score}
              </div>
            </div>
          </div>
        </div>

        <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header as="div" >
            <div className="nft__item_action">
              <span>Expand more</span>
            </div>
          </Accordion.Header>

          <Accordion.Body >
            <div className="nft__item_moreinfo">
              <div>
                <h4>Features</h4>
                <div dangerouslySetInnerHTML={{ __html: features }} />
              </div>
              <div>
                <h4>Welcome Offer</h4>
                <div dangerouslySetInnerHTML={{ __html: welcome_offer }} />
              </div>
              <div className="nft__item_actnbtn">
                <div
                  role="button"
                  className="btn-main nft__item_actn"
                  onClick={handleBtnClick}
                >
                  View full details
                </div>
                <div
                  role="button"
                  className="btn-main sec nft__item_actn "
                  onClick={() => handleViewRewardCalc(navigate, card_name)}
                >
                  Calculate Reward
                </div>
              </div>
            </div>
          </Accordion.Body>
          </Accordion.Item>

        </Accordion>
      </div>
    </div>
  );
};
