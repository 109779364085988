import BaseContainer from "../components/BaseContainer";
import creditcard_data from "../../data/creditcard_data.json";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Accordion, Offcanvas } from "react-bootstrap";
import { Link } from "@reach/router";
import { getCardViewUrl } from "../../utility/helper";
import { Helmet } from "react-helmet-async";
import StarRating from "../components/StarRating";
import { useParams } from "@reach/router";
import { useEffect } from "react";
import { genCardDisplayName } from "../../utils";

const ComparePage = () => {
  const { cardNames: paramCardName } = useParams();

  const [show, setShow] = useState(false);

  const [cardName1, setCardName1] = useState("Airtel Axis Bank Credit Card");
  const [cardName2, setCardName2] = useState("");

  useEffect(() => {
    const cardNamesList = paramCardName.split("-vs-");
    console.log("useEffect", paramCardName, cardNamesList);

    if (cardNamesList.length >= 1) {
      setCardName1(cardNamesList[0]);
    }
    if (cardNamesList.length >= 2) {
      setCardName2(cardNamesList[1]);
    }
  }, [paramCardName]);

  const cardMainData1 =
    cardName1 &&
    creditcard_data.product_list.find((itm) =>
      itm.key.toLowerCase().includes(cardName1.toLowerCase())
    );

  const cardMainData2 =
    cardName2 &&
    creditcard_data.product_list.find((itm) =>
      itm.key.toLowerCase().includes(cardName2.toLowerCase())
    );

  const handleRemoveCard = (cardNumber) => {
    if (cardNumber === 1) {
      setCardName1(cardName2);
      setCardName2("");
    } else if (cardNumber === 2) {
      setCardName2("");
    }
  };

  const handleAddCard = (cardNumber, cardName) => {
    console.log(cardName, cardNumber);
    if (cardNumber === 1) {
      setCardName1(cardName);
    } else {
      setCardName2(cardName);
    }
    setShow(false);
  };

  const OffCanvas = () => (
    <Offcanvas show={show} onHide={() => setShow(false)}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title> Credit Card for Comparision</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="h-100">
        <label>Select Credit Card 1</label>
        <Typeahead
          id="cardselector"
          onChange={(_selected) => handleAddCard(1, _selected[0].key)}
          labelKey={(option) => `${option.card_name} `}
          placeholder={cardName1 || "Select card..."}
          options={creditcard_data.product_list}
        />
        <label className="mt-3">Select Credit Card 2</label>
        <Typeahead
          id="cardselector"
          onChange={(_selected) => handleAddCard(2, _selected[0].key)}
          labelKey={(option) => `${option.card_name} `}
          placeholder={cardName2 || "Select card..."}
          options={creditcard_data.product_list}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );

  return (
    <BaseContainer
      h1={"Credit Cards "}
      h1Strong="Compare"
      content="Compare credit cards on various metrics to decide better."
    >
      <Helmet>
        {/* Standard Meta */}
        <title>{"Compare credit cards at TrueCards.in"} </title>
      </Helmet>
      <OffCanvas />

      <div className="mb-4">
        <div className="mb-3">
          <button className="btn btn-main" onClick={() => setShow(true)}>
            Add cards <i className="fa fa-plus-circle" role="button" />
          </button>
        </div>
        {cardName1 && (
          <div className="mb-3">
            Comparing <Link to={getCardViewUrl(cardName1)}>{cardName1}</Link>{" "}
            <span className="text-accent">{cardName2 && "vs "}</span>
            <Link to={getCardViewUrl(cardName2)}>{cardName2}</Link>
          </div>
        )}
      </div>

      <div className="card-wrap">
        <CompareRow
          field=""
          v1={
            cardName1 ? (
              <div className="position-relative text-center">
                <img
                  src={`./img/${cardMainData1?.product_image}`}
                  className="credit-card sm rounded mb-3"
                  alt={cardMainData1?.image_alt}
                />

                <div
                  className="position-absolute top-0 end-0"
                  onClick={() => handleRemoveCard(1)}
                >
                  <i className="fa fa-times-circle" role="button" />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div
                  onClick={() => setShow(true)}
                  role="button"
                  className="credit-card sm bg-light border d-flex justify-content-center align-items-center"
                >
                  <i className="fa fa-plus-circle" />
                </div>
              </div>
            )
          }
          v2={
            cardName2 ? (
              <div className="position-relative text-center">
                <img
                  src={`./img/${cardMainData2?.product_image}`}
                  className="credit-card sm rounded mb-3"
                  alt={cardMainData2?.image_alt}
                />

                <div
                  className="position-absolute top-0 end-0"
                  onClick={() => handleRemoveCard(2)}
                >
                  <i className="fa fa-times-circle" role="button" />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <div
                  onClick={() => setShow(true)}
                  role="button"
                  className="credit-card sm bg-light border d-flex justify-content-center align-items-center"
                >
                  <i className="fa fa-plus-circle" />
                </div>
              </div>
            )
          }
        />

        <CompareRow
          field="Card Name"
          v1={cardMainData1?.card_name}
          v2={cardMainData2?.card_name}
          decorator={(_field) => <strong>{_field}</strong>}
        />
        <CompareRow
          field="Rating"
          v1={cardMainData1?.rating}
          v2={cardMainData2?.rating}
          decorator={(_field) => <StarRating rating={_field} />}
        />
        <CompareRow
          field="Category"
          v1={cardMainData1?.welcome_benefits}
          v2={cardMainData2?.welcome_benefits}
          decorator={(_field) =>
            _field?.replace(/'/g, "").split(",").join(", ")
          }
        />
        <CompareRow
          field="Joining Fee"
          v1={cardMainData1?.joining_fee}
          v2={cardMainData2?.joining_fee}
          decorator={(_field) => <span>&#8377;{_field}</span>}
        />
        <CompareRow
          field="Annual Fee"
          v1={cardMainData1?.annual_fee}
          v2={cardMainData2?.annual_fee}
          decorator={(_field) => <span>&#8377;{_field}</span>}
        />
        <CompareRow
          field="APR"
          v1={cardMainData1?.apr}
          v2={cardMainData2?.apr}
          decorator={(_field) => <span>{_field}% Annually</span>}
        />
        <CompareRow
          field="Domestic Lounge Access"
          v1={cardMainData1?.lounge_access}
          v2={cardMainData2?.lounge_access}
          decorator={(_field) => <span>{_field === 1 ? "Yes" : "No"}</span>}
        />
      </div>
    </BaseContainer>
  );
};

const CompareRow = ({ field, v1, v2, decorator = (_p) => _p }) => {
  return (
    <>
      <div className="d-none d-md-flex row cmp-container" key={`comp-field-${field}`}>
        <div className="col-4 cmp-cell text-start">
          <strong>{field}</strong>
        </div>
        <div className="col-4 cmp-cell text-start">{decorator(v1) || "-"}</div>
        <div className="col-4 cmp-cell text-start">{decorator(v2) || "-"}</div>
      </div>

      <Accordion className="d-md-none " defaultActiveKey={"0"}>
        <Accordion.Item eventKey="0">
          <Accordion.Header as="div" className="" bsPrefix="">
             {field}
          </Accordion.Header>

          <Accordion.Body>
            <div className="row cmp-container">
              <div className="col-6 cmp-cell text-start">
                {decorator(v1) || "-"}
              </div>
              <div className="col-6 cmp-cell text-start">
                {decorator(v2) || "-"}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
export default ComparePage;
