import React from "react";
import InfoPop from "./InfoPop";

const BaseContainer = ({ children, id, title, content, h1, h1Strong }) => {
  return (
    <section className="reward_container container  p-md-5">
      <h1>
        {h1}
        <strong>{h1Strong}</strong>
        &nbsp;
        {content && <InfoPop
          id={id || "maininfo"}
          title={title || `${h1} ${h1Strong}`}
          content={content}
        />}
      </h1>

      {children}
    </section>
  );
};

export default BaseContainer;
