import React, { useState } from "react";
import { InputGroup, Form, Breadcrumb } from "react-bootstrap";
import { useParams, navigate } from "@reach/router"; // Import from @reach/router
import { useEffect } from "react";
import creditcard_data from "../../data/creditcard_data.json";
import creditcard_reward_data from "../../data/creditcard_reward_data.json";
import { Helmet } from "react-helmet-async";
import InfoPop from "../components/InfoPop";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { getCardViewUrl } from "../../utility/helper";

const fadeInDown = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const RewardCalculator = () => {
  const { cardName } = useParams(); // Get the bank name from URL params
  const cardDisplayName = cardName.replace(/-/g, " ");
  const [isCategoryExpanded, setIsCategoryExpanded] = useState(false); // Toggle for category input

  const [cardInfo, setCardInfo] = useState({});
  const rewardCategories = cardInfo.reward_category || [];
  const redemptionOptions = cardInfo.redeem_options || [];

  useEffect(() => {
    if (cardName) {
      const rewardRecord = creditcard_reward_data.product_list.find(
        (rec) => rec.card_name === cardDisplayName
      );
      const mainCardRecord = creditcard_data.product_list.find(
        (rec) => rec.card_name === cardDisplayName
      );

      console.log("mainCardRecord", mainCardRecord);
      setCardInfo({ ...rewardRecord, ...mainCardRecord });
      setChoosenRedemption(rewardRecord.redeem_options[0]?.name);
    }
    return () => {};
  }, [cardName]);

  const [categorySpending, setCategorySpending] = useState({
    general: 0,
    //dynamic
  });
  const [totalSpending, setTotalSpending] = useState(0); // Total spending

  const [categoryRewardEarned, setCategoryRewardEarned] = useState({
    general: 0,
    //other categories are dynamic
  });
  const [totalRewardPoints, setTotalRewardPoints] = useState(0); // Total spending

  const [choosenRedemption, setChoosenRedemption] = useState("");

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    const _spentValue = value === "" ? "" : Number(value);

    const updatedSpending = { ...categorySpending, [name]: _spentValue };
    setCategorySpending(updatedSpending);

    const _totalSpent = Object.values(updatedSpending).reduce(
      (acc, curr) => acc + Number(curr),
      0
    );
    setTotalSpending(_totalSpent);
    console.log("_totalSpent", _spentValue, updatedSpending, _totalSpent);

    // Calculate rewards dynamically
    const categoryReward = rewardCategories?.find((rec) => rec.key == name);
    const rewardRate = categoryReward.rp / categoryReward.spendMultiplier;
    let pointEarned = Math.round(rewardRate * _spentValue);
    const updatedCategoryRewardEarned = {
      ...categoryRewardEarned,
      [name]: categoryReward.cap
        ? Math.min(pointEarned, categoryReward.cap)
        : pointEarned,
    };
    setCategoryRewardEarned(updatedCategoryRewardEarned);

    const _totalRewardPoints = Math.round(
      Object.values(updatedCategoryRewardEarned).reduce(
        (acc, curr) => acc + curr,
        0
      )
    );
    setTotalRewardPoints(_totalRewardPoints);
    console.log(
      "handleCategorySpendingChange",
      _spentValue,
      rewardRate,
      pointEarned
    );
  };

  // Handle manual input for total spending
  const handleTotalSpendingChange = (e) => {
    // const _spentValue = e.target.value;
    const _spentValue = e.target.value === "" ? "" : Number(e.target.value);

    setTotalSpending(_spentValue);

    const categoryReward = rewardCategories?.find(
      (rec) => rec.key === "general"
    );
    const rewardRate = categoryReward.rp / categoryReward.spendMultiplier;
    let pointEarned = Math.round(rewardRate * _spentValue);
    setTotalRewardPoints(pointEarned);
    console.log(
      "handleTotalSpendingChange",
      _spentValue,
      rewardRate,
      pointEarned
    );
  };

  const totalProfit = Math.round(
    choosenRedemption
      ? (
          totalRewardPoints *
          redemptionOptions?.find((rec) => rec.name === choosenRedemption)
            ?.ratePerPoint
        )?.toFixed(2)
      : 0
  );

  if (redemptionOptions.length == 0 || rewardCategories.length == 0)
    return <div>loding</div>;
  return (
    <div className="reward_container container  p-md-5">
      <Helmet>
        {/* Standard Meta */}
        <title>{cardInfo.meta_title}</title>
        <meta name="description" content={cardInfo.meta_description} />
        <link rel="canonical" href={cardInfo.meta_url} />

        {/* Open Graph Meta */}
        <meta property="og:title" content={cardInfo.meta_title} />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content={cardInfo.meta_url} />
        <meta property="og:description" content={cardInfo.meta_description} />
        {/* <meta property="og:image" content={cardInfo.og_image} /> */}
        {/* <meta property="og:image:alt" content={cardInfo.og_image_alt} />
        <meta property="og:image:type" content={cardInfo.og_image_type} /> */}
        <meta property="og:site_name" content={"truecards"} />

        {/* Twitter Meta */}
      </Helmet>
      <h1>
        Credit Card <strong> Reward Calculator</strong>
        &nbsp;
        <InfoPop
          id="Reward_calc"
          title="Why Use It?"
          content=" Use calculator to estimates your potential cashback and Future
                saving potentials based on yearly spending."
        />
      </h1>

      <div className="reward_wrap ">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <i className="fa fa-home" />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/reward-calculator/select-card">
            Reward Calculator
          </Breadcrumb.Item>
          <Breadcrumb.Item href={getCardViewUrl(cardInfo.card_name)}>
            {cardInfo.card_name}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Reward Calculator</Breadcrumb.Item>
        </Breadcrumb>
        <div className="card-wrap">
          <div className="reward_wrap_backbtn">
            <i
              role="button"
              className="fa fa-arrow-circle-o-left"
              onClick={() => navigate("/reward-calculator/select-card")}
            />
          </div>

          {/* {cardInfo.status != 1 && (
            <h5 className="bg-gray">
              Not accurate for this card. Currently working on this page. Last
              updated : 19-12-2024
            </h5>
          )} */}
          <div className="text-center">
            <img
              src={`./img/${cardInfo.product_image}`}
              className="img-rounded mb-2"
              alt={cardInfo.image_alt}
              width="auto"
              height={70}
              // style={{height:}}
            />
            <h2>{cardDisplayName}</h2>
          </div>

          <h5 className="d-inline">Step 1. Enter estimated expense </h5>
          <InfoPop
            id="Spending"
            title="Estimate your spending yearly"
            content="Simply enter your estimated annual spending in different
                categories like dining, travel, and groceries."
          />

          <div className="reward_wrap_desc"></div>
          {/* Total Spending Input */}
          <div className="reward_wrap_item">
            <label>
              Total Spending{" "}
              <InfoPop
                id="total Spending"
                title="Total Spending"
                content="Enter your total estimated expense per year. Expand and enter category based spending for accurate reward calcualtion."
              />
            </label>
            <InputGroup size="sm" className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-sm">₹</InputGroup.Text>
              <Form.Control
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                type="number"
                value={totalSpending}
                onChange={handleTotalSpendingChange}
                disabled={isCategoryExpanded}
                min={0}
              />
              {/* <Form.Text id="totalRPBlock" muted>
                Your password must be 8-20 characters long, contain letters and
                numbers, and must not contain spaces, special characters, or
                emoji.
              </Form.Text> */}
            </InputGroup>
          </div>

          {/* Expandable Category Breakdown */}
          <div style={{ marginTop: "10px" }}>
            <button
              onClick={() => setIsCategoryExpanded(!isCategoryExpanded)}
              style={{
                padding: "8px",
                font: "14px",
                backgroundColor: isCategoryExpanded ? "#f44336" : "#4caf50",
                color: "#fff",
                border: "none",
                cursor: "pointer",
              }}
            >
              {isCategoryExpanded
                ? "Close Category Breakdown "
                : "Add Category Breakdown "}
              <i className="fa fa-plus" />
            </button>
          </div>

          {isCategoryExpanded && (
            <Reveal
              className="onStep"
              keyframes={fadeInDown}
              delay={0}
              duration={600}
              triggerOnce
            >
              <div
                className={`reward_wrap_cate ${
                  isCategoryExpanded ? "open" : ""
                }`}
              >
                {rewardCategories?.map((categoryRec) => (
                  <div key={categoryRec.name} className="reward_wrap_item">
                    <label>
                      {`${categoryRec.name} `}
                      <InfoPop
                        id={categoryRec.name}
                        title={`${categoryRec.name} `}
                        content={`${categoryRec.description} ${
                          categoryRec.cap_desc || ""
                        }`}
                      />
                    </label>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        ₹
                      </InputGroup.Text>
                      <Form.Control
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        type="number"
                        name={categoryRec.key}
                        value={categorySpending[categoryRec.key]}
                        onChange={handleCategoryChange}
                        min={0}
                        inputMode="numeric"
                      />
                    </InputGroup>
                  </div>
                ))}
              </div>
            </Reveal>
          )}

          <div className="summary-container mt-4">
            <strong className="mb-2">Expense Summary</strong>
            <div className="summary-item reward_wrap_item">
              <label>Total Spending:</label>
              <p>₹{totalSpending || "0"}</p>
            </div>
            <div className="summary-item reward_wrap_item">
              <label>Total Reward Points Earned:</label>
              <p>₹{totalRewardPoints}</p>
            </div>

            <div className="summary-item d-none">
              <h4>Reward Points Earned:</h4>
              <ul>
                {Object.keys(categoryRewardEarned).map((category) => (
                  <li key={category}>
                    <strong>{category}:</strong>{" "}
                    {categoryRewardEarned[category]}
                    points
                  </li>
                ))}
              </ul>
              <h5>Total Reward Points: {totalRewardPoints}</h5>
            </div>

            <hr className="hr my-3" />

            <div className="summary-item">
              <h5 className="d-inline">Step 2. Redemption Options </h5>

              <InfoPop
                id="Redemption"
                title="Choose Best Redemption Options"
                content="Choose best or your prefered option to maximize your reward conversion rate."
              />

              <Form className="mt-2">
                {redemptionOptions?.map((option) => (
                  <Form.Check
                    key={option.name}
                    type="radio"
                    id={`redem-${option.name}`}
                    value={option.name}
                    role="button"
                    checked={choosenRedemption === option.name} // Check based on state
                    onChange={(e) => setChoosenRedemption(e.target.value)}
                    label={
                      <p role="button">
                        <strong>{option.name}</strong>
                        {` 1RP = ${option.ratePerPoint.toFixed(2)}`}
                      </p>
                    }
                  />
                ))}
              </Form>
            </div>

            <hr className="hr my-2" />
            <div className="summary-item">
              <h5>Reward Summary:</h5>
              <div className="reward_wrap_item">
                <label>Total Profit : </label>
                <p>₹{totalProfit}</p>
              </div>
              <div className="reward_wrap_item">
                <label>Effective Reward Rate : </label>
                <p>
                  {totalSpending >= 1
                    ? ((totalProfit / totalSpending) * 100)?.toFixed(2)
                    : "0.0"}
                  %
                </p>
              </div>
              <div className="reward_wrap_item text-dark">
                {totalSpending > 0
                  ? `You will earn approx. ₹${totalProfit} profit on spend of ₹${totalSpending} if your choose to redeem your ${totalRewardPoints} reward points earned by ${choosenRedemption}.`
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardCalculator;
