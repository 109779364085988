import { Suspense, lazy } from "react";

import SliderMain from "../components/SliderMain";
import Loading from "../components/Loading";

const CarouselNew = lazy(() => import("../components/CarouselNew"));
const Catgor = lazy(() => import("../components/Catgor"));
const BankList = lazy(() => import("../components/BankList"));

// import CarouselNew from "../components/CarouselNew";

const home = () => (
  <div>
    <section className="jumbotron breadcumb no-bg h-vh">
      <div className="quater-cirlce"></div>
      <SliderMain />
    </section>

    {/* <section className='container no-top no-bottom'>
        <FeatureBox/>
      </section> */}

    <section className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h2>Top Credit Card Categories </h2>
            <div className="small-border"></div>
          </div>
        </div>
      </div>
      <Suspense fallback={<Loading />}>
        <Catgor />
      </Suspense>
    </section>

    <section className="container no-bottom">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h2>Most Popular Credit Cards in India</h2>
            <div className="small-border"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <Suspense fallback={<Loading />}>
            <CarouselNew />
          </Suspense>
        </div>
      </div>
    </section>

    {/* <section className='container no-bottom'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>CREDIT CARD BY CREDIT ISSUER</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <CarouselCollection/>
          </div>
        </div>
      </section> */}

    <section className="container no-bottom">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h2>Top Credit Card by Issuer</h2>
            <div className="small-border"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <Suspense fallback={<Loading />}>
            <BankList />
          </Suspense>
        </div>
      </div>
    </section>
  </div>
);
export default home;
