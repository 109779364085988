import { navigate } from "@reach/router";
import React from "react";
import { useState } from "react";

const SearchBar = (props) => {
    const [searchQuery, setSearchQuery] = useState(props.initSearchQuery);
    const handleSearch =(event)=>{
        event.preventDefault(); 
        console.log("Search Query", searchQuery)
        navigate(`/credit-cards/?search_q=${searchQuery}`);
    }
    
  return (
    <form
      className="row form-dark"
      id="form_quick_search"
      name="form_quick_search"
    >
      <div className="col">
        <input
          className="form-control"
          id="search_q"
          name="search_q"
          placeholder="search card here..."
          type="text"
          value={searchQuery}
          onChange={(e)=>setSearchQuery(e.target.value)}
        />{" "}
        <button className="btn-submit" onClick={handleSearch} >
          <i className="fa fa-search bg-color-secondary"></i>
        </button>
        <div className="clearfix"></div>
      </div>
    </form>
  );
};

export default SearchBar;
