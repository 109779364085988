import { Suspense, lazy, useState, useEffect } from "react";
import { Link, useParams } from "@reach/router"; // Import from @reach/router
import creditcard_data from "../../data/creditcard_data.json";
import creditcard_reward_data from "../../data/creditcard_reward_data.json";
import cardcategory_data from "../../data/cardcategory_data.json";

import { Helmet } from "react-helmet-async";
import { Tabs, Tab, Breadcrumb, ListGroup } from "react-bootstrap";
import { getBankViewUrl, getCardViewUrl } from "../../utility/helper";
import { handleCompareCard, handleViewRewardCalc } from "../../utility/navigationHelper";
import { navigate } from "@reach/router";
import Loading from "../components/Loading";

const FaqSection = lazy(() => import("../components/cardDetails/CardFaq"));
const StarRating = lazy(() => import("../components/StarRating"));
const CardOverview = lazy(() =>
  import("../components/cardDetails/CardOverview")
);
const CardProsCons = lazy(() =>
  import("../components/cardDetails/CardProsCons")
);
const CardRewards = lazy(() => import("../components/cardDetails/CardRewards"));

const CardDetailView = () => {
  const { cardName } = useParams(); // Get the bank name from URL params
  const [cardInfo, setCardInfo] = useState({});

  useEffect(() => {
    console.log("Looking for card ", cardName);
    const cardMainData = creditcard_data.product_list.find((itm) =>
      itm.card_name
        .toLowerCase()
        .includes(cardName.replace(/-/g, " ").toLowerCase())
    );
    const cardRewardData = creditcard_reward_data.product_list.find((itm) =>
      itm.card_name
        .toLowerCase()
        .includes(cardName.replace(/-/g, " ").toLowerCase())
    );
    setCardInfo({
      ...cardMainData,
      ...cardRewardData,
    });
  }, [cardName]);

  const schemaOrgJsonLd = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: cardInfo?.card_name,
    brand: {
      "@type": "Organization",
      name: cardInfo?.bank_name,
    },
    description: cardInfo?.meta_description,
    // ... other Product schema properties (see previous example)

    // offers: {
    //   "@type": "Offer",
    //   // ... offer details
    // },

    // aggregateRating: {
    //   // ... aggregate rating details
    // },

    // // ... other Product schema properties
    image: "https://[your_website]/images/hdfc_regalia_card.jpg",

    faq: cardInfo?.faq?.map((faq) => ({
      "@type": "Question",
      name: faq.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    })),
  };
  const firstCategory = cardInfo?.welcome_benefits
    ?.replace(/'/g, "")
    .split(",")[0];
  return (
    <div className="bg-gray">
      <Helmet>
        {/* Standard Meta */}
        <title>{cardInfo?.meta_title || "TrueCards"} </title>
        <meta name="description" content={cardInfo?.meta_description} />
        <meta name="robots" content={cardInfo?.meta_robots} />
        <link rel="canonical" href={cardInfo?.meta_url} />

        {/* Open Graph Meta */}
        <meta property="og:title" content={cardInfo?.og_title} />
        <meta property="og:type" content={cardInfo?.og_type} />
        <meta property="og:url" content={cardInfo?.og_url} />
        <meta property="og:description" content={cardInfo?.og_description} />
        {/* <meta property="og:image" content={cardInfo?.og_image} /> */}
        <meta property="og:image:alt" content={cardInfo?.og_image_alt} />
        <meta property="og:image:type" content={cardInfo?.og_image_type} />
        <meta property="og:site_name" content={cardInfo?.og_site_name} />

        {/* Twitter Meta */}
        <meta name="twitter:card" content={cardInfo?.twitter_card} />
        <meta name="twitter:site" content={cardInfo?.twitter_site} />
        <meta name="twitter:title" content={cardInfo?.twitter_title} />
        <meta
          name="twitter:description"
          content={cardInfo?.twitter_description}
        />
        {/* <meta name="twitter:image" content={cardInfo?.twitter_image} /> */}
        <meta name="twitter:image:alt" content={cardInfo?.twitter_img_alt} />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJsonLd)}
        </script>
      </Helmet>

      <section className="container">
        <div className="row mt-md-2 ">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              <i className="fa fa-home" />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/credit-cards">Credit Cards</Breadcrumb.Item>

            <Breadcrumb.Item href={getBankViewUrl(cardInfo?.bank_name)}>
              {cardInfo?.bank_name}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{cardInfo?.card_name}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="col-md-9 mb-3">
            <div className="item_info_main">
              <div className="item_info row">
                <div className="col-lg-4  text-center text-md-start">
                  <img
                    src={`./img/${cardInfo?.product_image}`}
                    className="img-rounded mb-sm-30 credit-card animate text-center text-md-start"
                    alt={cardInfo?.image_alt}
                  />
                </div>
                <div className="col-lg-8">
                  {/* Auctions ends in
                  <div className="de_countdown">
                    <Clock deadline="December, 30, 2021" />
                  </div> */}
                  <h2 className="text-center text-md-start mt-2">
                    {cardInfo?.card_name}
                  </h2>
                  <Suspense fallback={<Loading />}>
                    <StarRating className="mb-2" rating={cardInfo?.rating} />
                  </Suspense>

                  <div className="item_info_counts">
                    {cardInfo?.welcome_benefits
                      ?.replace(/'/g, "")
                      .split(",")
                      .map((itm) => {
                        const _cate = cardcategory_data?.category_list?.find(
                          (cate) => cate.name === itm
                        );
                        return (
                          <div
                            key={`category_${itm}`}
                            className="item_info_type"
                          >
                            <i className={`fa ${_cate?.icon || "fa-heart"}`} />
                            {itm}
                          </div>
                        );
                      })}
                  </div>
                  <div className="d-inline-flex gap-2">
                    <div
                      role="button"
                      className="btn-main sec btn-sm  "
                      onClick={() =>
                        handleViewRewardCalc(navigate, cardInfo?.card_name)
                      }
                    >
                      Calculate Reward{"   "}
                      <i className="fa fa-share-square-o" />
                    </div>
                    <div
                      role="button"
                      className="btn-main sec btn-sm  "
                      onClick={() =>
                        handleCompareCard(navigate, cardInfo?.key)
                      }
                    >
                      Compare this Card{"   "}
                      <i className="fa fa-share-square-o" />
                    </div>
                  </div>
                </div>
                <div className="mt-2 text-dark">
                  <strong className="d-inline">Best for: </strong>
                  <span>{cardInfo?.best_for}</span>
                </div>
                <div className="mt-2 mb-3 text-dark">
                  <strong className="d-inline">Lounge Access: </strong>
                  <span> {cardInfo?.lounge_access === "1" ? "Yes" : "No"}</span>
                </div>

                <div className="de_tab" id="tabs2">
                  <Tabs fill defaultActiveKey="overview">
                    <Tab eventKey="overview" title="Overview">
                      <Suspense fallback={<Loading />}>
                        <CardOverview
                          className="nft__item_moreinfo"
                          cardInfo={cardInfo}
                        />{" "}
                      </Suspense>
                    </Tab>
                    <Tab eventKey="reward" title="Reward">
                      <Suspense fallback={<Loading />}>
                        <CardRewards
                          className="nft__item_moreinfo"
                          cardInfo={cardInfo}
                        />{" "}
                      </Suspense>
                    </Tab>
                    <Tab eventKey="pros_cons" title="Pros/Cons">
                      <Suspense fallback={<Loading />}>
                        <CardProsCons
                          cardInfo={cardInfo}
                          className="nft__item_moreinfo"
                        />
                      </Suspense>
                    </Tab>

                    <Tab eventKey="faq" title="Faq">
                      <Suspense fallback={<Loading />}>
                        <FaqSection
                          className="nft__item_moreinfo"
                          faqs={cardInfo?.faq}
                        />
                      </Suspense>
                    </Tab>
                    {/* <Tab eventKey="lounge" title="Lounge" disabled>
                      <div className="onStep fadeIn">
                        <div className=" mt-3">
                          <table className="w-100">
                            <tr>
                              <th>City</th>
                              <th>Lounge</th>
                              <th>Terminal</th>
                            </tr>
                            <tr>
                              <td>Alfreds Futterkiste</td>
                              <td>Maria Anders</td>
                              <td>Germany</td>
                            </tr>
                            <tr>
                              <td>Centro comercial Moctezuma</td>
                              <td>Francisco Chang</td>
                              <td>Mexico</td>
                            </tr>
                            <tr>
                              <td>Centro comercial Moctezuma</td>
                              <td>Francisco Chang</td>
                              <td>Mexico</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </Tab> */}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 ">
            <div className="item_info_left  ">
              {/* <div role="button" className="item_info_left__tag">
              Card Details
            </div>
            <div role="button" className="item_info_left__tag">
              Expert Review
            </div>
            <div role="button" className="item_info_left__tag">
              Eligibility
            </div> */}
              {cardInfo?.related_card_names?.length > 0 && (
                <div>
                  <h4>Related Cards</h4>
                  <ListGroup>
                    {cardInfo?.related_card_names?.map((_card_name) => (
                      <ListGroup.Item key={`related-cards-${_card_name}`}>
                        <Link to={getCardViewUrl(_card_name)}>
                          {_card_name}
                        </Link>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </div>
              )}
              <div>
                <h4 className="mt-3">{`More ${firstCategory} cards`}</h4>
                <ListGroup>
                  {creditcard_data.product_list
                    .filter((_card) =>
                      _card.welcome_benefits.includes(firstCategory)
                    )
                    .splice(0, 5)
                    .map((_card) => (
                      <ListGroup.Item
                        key={`more-cate-cards-${_card.card_name}`}
                      >
                        <Link to={getCardViewUrl(_card.card_name)}>
                          {_card.card_name}
                        </Link>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </div>
              <div>
                <h4 className="mt-3">{`More ${cardInfo?.bank_name} cards`}</h4>
                <ListGroup>
                  {creditcard_data.product_list
                    .filter((_card) =>
                      _card.bank_name.includes(cardInfo?.bank_name)
                    )
                    .splice(0, 5)
                    .map((_card) => (
                      <ListGroup.Item
                        key={`more-bank-cards-${_card.card_name}`}
                      >
                        <Link to={getCardViewUrl(_card.card_name)}>
                          {_card.card_name}
                        </Link>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CardDetailView;
