import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import creditcard_data from "../../data/creditcard_data.json";
import { navigate } from "@reach/router";
import { getRewardCalcUrl } from "../../utility/helper";

export default function LoungeAccessCardSelector() {
  const [selectedBank, setSelectedBank] = useState([]);
  const [cardOption, setCardOption] = useState([]);
  const [cardName, setCardName] = useState([]);

  const bankOptions = [
    ...new Set(creditcard_data.product_list.map((obj) => obj.bank_name)),
  ].sort();

  const handleGoBack = () => {
    if (window.history.state) {
      window.history.back(); // Goes to the previous page
    } else {
      navigate("/"); // Fallback to home or a default route
    }
  };

  return (
    <div className="reward_container container  p-md-5">
      <div className="reward_wrap card-wrap">
        <div className="reward_wrap_backbtn">
          <i
            role="button"
            className="fa fa-arrow-circle-o-left"
            onClick={handleGoBack}
          />
          {/* <i role="button" className="fa fa-arrow-circle-o-right" /> */}
        </div>

        <h1 className="mb-4">
          Browse Lounge Access List <strong> Credit Cards</strong>
        </h1>
        {/* <h2>Airtel Axis Bank Credit Card</h2> */}
        <div>
          <label>Bank/Issuer</label>
          <Typeahead
            id="bankselector"
            onChange={(_selected) => {
              setSelectedBank(_selected);
              setCardOption(
                creditcard_data.product_list.filter(
                  (rec) => rec.bank_name === _selected[0] 
                )
              );
              setCardName([])
            }}
            //   labelKey={option => `${option.bank_name} `}
            placeholder="Select bank/issuer..."
            options={bankOptions}
            selected={selectedBank}
          />
        </div>
        <div className="mt-2">
          <label>Card Variant</label>
          <Typeahead
            id="cardselector"
            onChange={(selected) => {
              setCardName(selected);
            }}
            labelKey={(option) => `${option.card_name} `}
            placeholder="Select card..."
            options={cardOption}
            selected={cardName}
          />
        </div>
        <button
          className="btn-main reward-btn mt-4"
          disabled={cardName.length === 0}
          onClick={() => {
            navigate(getRewardCalcUrl(cardName[0].card_name));
          }}
        >
          Show Lounge Access List
        </button>
      </div>
    </div>
  );
}
